$(function(global) {

	$('.ajaxForm').on('submit', function (e) {
		e.preventDefault();
		ajaxForm(e.target.id);
	});

	/*
	$("#mobilebuttonmenu").click(function() {
		$("#mobilemenu").toggleClass("d-none");
		if ($("#mobilebuttonmenu").hasClass("shown")) {
			$('#mobilebuttonmenu').removeClass('shown');
			$('#mobilebuttonmenu i').removeClass('fa-chevron-left').addClass('fa-list');
			$("html").removeClass("no-overflow");
		} else {
			$('#mobilebuttonmenu').addClass('shown');
			$('#mobilebuttonmenu i').removeClass('fa-list').addClass('fa-chevron-left');
			$("html").addClass("no-overflow");
		}
	});
	*/

	$('#jumptolotinput').keyup(function(event){
		if (event.keyCode == 13) {
			jumptolot('jumptolotinput');
		}
	});

	/*
	$("#desktopMainSearchPanel .mainSearchInput").keydown(function(event) {
		if (event.which == 13) {
			event.preventDefault();
			$('#desktopMainSearchPanel .mainSearchButton').click();
		}
	});
	*/

	/*
	$("#mainSearchPriceModalMin").keydown(function(event) {
		if (event.which == 13) {
			event.preventDefault();
			$('#mainSearchPriceModalMax').select();
		}
	});
	$("#mainSearchPriceModalMax").keydown(function(event) {
		if (event.which == 13) {
			event.preventDefault();
			$('#mainSearchPriceModalButton').click();
		}
	});

	$('#mainSearchPriceModalWindow').on('shown.bs.modal', function (e) {
		$("#mainSearchPriceModalMin").select();
	});
	*/

	$('[data-fancybox="img"]').fancybox({
		speed: 200,
		slideShow: false,
		fullScreen: true,
		thumbs: false,
		loop: true,
		closeBtn: true,
		touch: {
			vertical: true
		},
		caption : function(instance, current) {
			var html =  '';
			if ($($(this).find('img')).data('caption')) {
				html += '<div class="text-center">' + $($(this).find('img')).data('caption') + '<br><br></div>';
			}
			html += '<div class="px-2"><div class="row"><div class="col-lg-8 mx-auto"><div class="row">';
			html += '<div class="col-sm-6"><div class="form-group row mb-0">';
			html += '<div class="col-2 col-form-label text-right"><label for="fancyboxcontrastfilter"><i class="material-icons fs-20">contrast</i></label></div>';
			html += '<div class="col-10 range"><p class="mb-0"><input type="range" class="form-control-range" id="fancyboxcontrastfilter" oninput="fancyboxImageFilters()" onchange="fancyboxImageFilters()" min="0.5" max="3" value="1" step="0.05"></p></div></div></div>'
			html += '<div class="col-sm-6"><div class="form-group row mb-0">';
			html += '<div class="col-2 col-form-label text-right"><label for="fancyboxbrightnessfilter"><i class="material-icons fs-20">light_mode</i></label></div>';
			html += '<div class="col-10 range"><p class="mb-0"><input type="range" class="form-control-range" id="fancyboxbrightnessfilter" oninput="fancyboxImageFilters()" onchange="fancyboxImageFilters()" min="0.5" max="3" value="1" step="0.05"></p></div></div></div>'
			html += '</div></div></div></div>';
			return html;
		},
		video: {
			tpl:
			'<video class="fancybox-video" controlsList="nodownload" poster="{{poster}}" loop>' +
			'<source src="{{src}}" type="{{format}}" />' +
			'Sorry, your browser doesn\'t support videos, <a href="{{src}}">download</a>' +
			"</video>",
			autoStart: true
		},
		spinnerTpl: '<div class="preloaderbackground"><div class="preloaderlogo"><div class="spin"><div class="logo"><div class="hammer"></div></div></div></div></div>'
	});
	
	$('#mobile-nav-categories-content .session-name').click(function() {
		let parent = $(this);
		$(parent).toggleClass('open');
		$(parent).next('.session-list').toggleClass('open');
	});
	
});

global.mainpreloader = mainpreloader;
function mainpreloader() {
	$('#mainpreloader').show();
}

global.toaster = toaster;
function toaster(type, head, body, small = '') {
	var id = +new Date();
	var html = '';
	html += '<div class="toast topscreen mb-1 bg-' + type + '" id="toast' + id + '" role="alert" aria-live="assertive" aria-atomic="true">';
	/*
	html += '<div class="toast-header bg-' + type + ' text-white">';
	html += '<strong class="mr-auto">' + head + '</strong>';
	if (small.length > 0) {
		html += '<small>' + small + '</small>';
	}
	html += '<button type="button" class="ml-2 mb-1 text-white close" data-dismiss="toast" aria-label="x"><span aria-hidden="true">&times;</span></button>';
	html += '</div>';
	*/
	html += '<div class="d-flex align-items-center">'
	html += '<div class="toast-body text-white">' + (head.length > 0 ? '<strong>' + head + '</strong><br/>' : '') + body + '</div>';
	html += '<button type="button" class="btn-close btn-close-white me-2" data-bs-dismiss="toast" aria-label="Close"></button>';
	html += '</div>';
	html += '</div>';
	$('#toastcontainer').append(html);
	$('#toast' + id).toast('show');
}

global.ajaxForm = ajaxForm;
function ajaxForm(element) {
	var form = $('#' + element);
	var $btn = false;
	var success = false;
	if ($('#' + element + ' .submit').data('loading-text')) {
		$btn = $('#' + element + ' .submit');
		$btn.button('loading');
	}
	if ($('#' + element).data('success')) {
		success = $('#' + element).data('success');
	}
	$('#' + element + ' .message_success').hide();
	$('#' + element + ' .message_error').hide();
	$('#' + element + ' div.form-group').removeClass('has-error');
	$.ajax({
		type: form.attr('method'),
		url: form.attr('action'),
		data: form.serialize()
	})
	.done(function (data) {
		if (typeof data.message !== 'undefined') {
			if (success) {
				$('#' + success).text(data.message).show();
			} else {
				$('#' + element + ' .message_success').text(data.message).show();
			}
			if (form.data('hide') == 1) {
				form.hide();
			}
			if (form.data('reload') == 1) {
				location.reload();
			}
			if (data.redirect) {
				window.location = data.redirect;
			}
		}
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		if (typeof jqXHR.responseJSON !== 'undefined') {
			var msg = jqXHR.responseJSON.message;
			var i = 0;
			if (jqXHR.status == 401) {
				msg += '<ul>';
				for (i = 0; i < jqXHR.responseJSON.errors.length; i = i + 1) {
					msg += '<li>' + jqXHR.responseJSON.errors[i] + '</li>';
				}
				msg += '</ul>';
			}
			$('#' + element + ' .message_error').html(msg).show();
			if (jqXHR.responseJSON.field !== undefined) {
				$('#' + jqXHR.responseJSON.field).focus();
				$('#' + jqXHR.responseJSON.field).closest('div.form-group').addClass('has-error')
			}
		} else {
			alert(errorThrown);
		}
	})
	.always(function() {
		if ($btn) {
			$btn.button('reset');
		}
	});
}

global.translate = translate;
function translate(str) {
	if (translator[str] == null) {
		return '[' + str + ']';
	}
	return translator[str];
}

global.exchange=exchange;
function exchange(json, value) {
	var int = Math.round(Math.floor(value / json.list[json.curr]) / 100);
	return {
		sign: (json.base != json.curr ? '~' : ''),
		value: int.formatMoney(0, '.', ' '),
		int: int,
		acr: json.curr
	}
}

global.exchangeCurrency=exchangeCurrency;
function exchangeCurrency(list, curr, value) {
	var int = Math.round(Math.floor(value / list[curr]) / 100);
	return {
		sign: (list[curr] != 1 ? '' : ''),
		value: int.formatMoney(0, '.', ' '),
		int: int,
		acr: curr
	}
}

global.setCurrency = setCurrency;
function setCurrency(value) {
	if (/^(ARS|AUD|BRL|BTC|CAD|CHF|CNY|CLP|CZK|DKK|ETH|EUR|GBP|HRK|HUF|INR|JPY|KRW|MXN|NOK|NZD|PLN|RUB|SAR|SEK|USD)$/.test(value)) {
		setCookie('currency', value, 10000);
		location.reload();
	}
}

global.setLanguage = setLanguage;
function setLanguage(value, url) {
	if (/^(bg|cs|da|de|el|en|es|fr|hi|hu|it|ja|ko|lt|lv|nl|no|pl|pt|ro|ru|sk|sv|tw|uk|zh)$/.test(value)) {
		setCookie('language', value, 10000);
		window.location = url.replace(/\?setlang=.*/, '') + '?setlang=' + value;
	}
}

global.jumptolot = jumptolot;
function jumptolot(input) {
	lot = $('#' + input).val().replace(/[^0-9A-Za-z\.]/, '');
	if (lot.length == 0) {
		toaster('danger', '', translate('brak takiej pozycji'));
		return;
	}
	$.ajax({
		url: '/' + $('#' + input).data('locale') + '/jumptolot/' + $('#' + input).data('auction') + '/' + lot,
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json) {
		window.location=json.url;
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('brak takiej pozycji'));
	})
}

global.loginAndRedirect=loginAndRedirect;
function loginAndRedirect(loginurl, backurl = '') {
	var expiry = new Date();
	expiry.setTime(expiry.getTime() + 300000);
	if (backurl == '') {
		backurl = window.location.host + window.location.pathname;
	}
	document.cookie = "loginredirect=" + backurl + "; expires=" + expiry.toGMTString() + "; domain=.onebid.pl; path=/; secure";
	window.location = loginurl;
}

global.mainSearchSwitch=mainSearchSwitch;
function mainSearchSwitch(lang, action, type, value) {
	$('#mainpreloader').show();
	var urlstring = btoa(window.location.pathname);
	$.ajax({
		url: '/' + lang + '/ajax/mainsearchswitch/' + action + '/' + type + '/' + value + '/' + urlstring,
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status != 200) {
			location.reload();
			return;
		}
		if (json.url.length == 0) {
			location.reload();
			return;
		}
		window.location = json.url;
	});
}

global.mainSearchPriceModalOpen = mainSearchPriceModalOpen;
function mainSearchPriceModalOpen(view) {
	var min = parseInt($('#mainSearchPriceModalWindow').data('min'));
	var max = parseInt($('#mainSearchPriceModalWindow').data('max'));
	$('#mainSearchPriceModalMin').val(min == 0 ? '' : min);
	$('#mainSearchPriceModalMax').val(max == 0 ? '' : max);
	$('#mainSearchPriceModalWindow').data('view', view);
	$('#mainSearchPriceModalWindow').modal('show');
}

global.mainSearchPriceModalSave = mainSearchPriceModalSave;
function mainSearchPriceModalSave(lang) {
	var view = $('#mainSearchPriceModalWindow').data('view');
	var tmpmin = $('#mainSearchPriceModalMin').val();
	var tmpmax = $('#mainSearchPriceModalMax').val();
	var min = (tmpmin == '' ? parseInt(0) : parseInt(tmpmin));
	var max = (tmpmax == '' ? parseInt(0) : parseInt(tmpmax));
	if (min > max && max > 0) {
		min = max;
	}

	$('.mainSearchPriceMin').html(min > 0 ? min.formatMoney(0, '.', ' ') : '1');
	$('.mainSearchPriceMax').html(max > 0 ? max.formatMoney(0, '.', ' ') : '&#8734;');
	$('#mainSearchPriceModalWindow').data('min', min);
	$('#mainSearchPriceModalWindow').data('max', max);
	
	$('#mainSearchPriceModalWindow').modal('hide');
	$('#mainpreloader').show();
	mainSearchShow(lang, view);
}

global.mainSearchShow = mainSearchShow;
function mainSearchShow(lang, view) {
	var auction = $('#search-form').data('auction');
	var type = $('#search-type').val();
	var name = $('#search-input').val().trim();
	if (name.length == 0) {
		name = '-';
	}
	var min = 0;
	var max = 0;
	var urlstring = btoa(window.location.pathname);
	if (/^[0-9]+$/.test($('#mainSearchPriceModalMin').val())) {
		min = $('#mainSearchPriceModalMin').val();
	}
	if (/^[0-9]+$/.test($('#mainSearchPriceModalMax').val())) {
		max = $('#mainSearchPriceModalMax').val();
	}

	$('#mainpreloader').show();
	$.ajax({
		url: '/' + lang + '/ajax/mainsearchshow/' + auction + '/' + type + '/' + min + '/' + max + '/' + encodeURIComponent(name.replace('/', '::')) + '/' + urlstring,
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status == 200) {
		//	console.log('REDIRECT ' + json.url);
			window.location = json.url;
		}
	})
	.fail(function() {
	//	window.location = '/';
	});
}

global.AHMessageSend = AHMessageSend;
function AHMessageSend(lang, department) {
	var message = $('#AHMessageModalInput').val().trim();
	var type = $('#AHMessageModalType').val().trim();
	var title = $('#AHMessageModalType option:selected').text();

	$('#AHMessageModalInputBox').removeClass('has-error');
	if (message.length < 3) {
		$('#AHMessageModalInputBox').addClass('has-error');
		$('#AHMessageModalInput').focus();
		return;
	}

	var $btn = $('#AHMessageModalButton').button('loading');
	$btn.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ' + $btn.data('loading-text'));
	$('#AHMessageModalClose').hide();
	$.ajax({
		url: '/' + lang + '/ajax/sendmessage/' + department + '/' + type,
		data: 'message=' + message + '&title=' + title,
		dataType: 'json',
		method: 'POST',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		var i = 0;
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		toaster('success', '', translate('zapisano'));
		$('#AHMessageModalWindow').modal('hide');
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	})
	.always(function() {
		$btn.html($btn.data('normal-text'));
		$('#AHMessageModalClose').show();
	});
}

global.AbuseMessageSend = AbuseMessageSend;
function AbuseMessageSend(lang, lot) {
	var message = $('#AbuseMessageModalInput').val().trim();

	$('#AbuseMessageModalInputBox').removeClass('has-error');
	if (message.length < 3) {
		$('#AHMessageModalInputBox').addClass('has-error');
		$('#AHMessageModalInput').focus();
		return;
	}
	
	var $btn = $('#AbuseMessageModalButton').button('loading');
	$btn.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ' + $btn.data('loading-text'));
	$('#AbuseMessageModalClose').hide();
	$.ajax({
		url: '/' + lang + '/ajax/sendabuse',
		data: {'message': message, 'lot': lot},
		dataType: 'json',
		method: 'POST',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		var i = 0;
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		toaster('success', '', translate('zapisano'));
		$('#AbuseMessageModalWindow').modal('hide');
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	})
	.always(function () {
		$btn.html($btn.data('normal-text'));
		$('#AbuseMessageModalClose').show();
	});
}

global.biographySend = biographySend;
function biographySend(lang) {
	var biography = $('#biographyModalInput').val().trim();
	var url= $(location).attr('href');

	$('#biographyModalInputBox').removeClass('has-error');
	if (biography.length < 5) {
		$('#biographyModalInputBox').addClass('has-error');
		$('#biographyModalInput').focus();
		return;
	}

	var $btn = $('#biographyModalButton').button('loading');
	$('#biographyModalClose').hide();
	$.ajax({
		url: '/' + lang + '/ajax/biography',
		data: 'biography=' + encodeURIComponent(biography) + '&url=' + url,
		dataType: 'json',
		method: 'POST',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		var i = 0;
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		toaster('success', '', translate('zapisano'));
		$('#biographyModal').modal('hide');
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	})
	.always(function() {
		$btn.button('reset');
		$('#biographyModalClose').show();
	});
}

global.sendMailVerify=sendMailVerify;
function sendMailVerify(lang) {
	$.ajax({
		url: '/' + lang + '/ajax/mailverify',
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		var i = 0;
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		toaster('success', '', json.message);
		$('#sendMailVerifyButton').hide();
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	});
}

global.setInputPercentProgress=setInputPercentProgress;
function setInputPercentProgress(len, max, element) {
	var percent = 0;
	if (len > 0) {
		percent = parseInt((len / max) * 100);
	}
	$('#' + element).css({width: percent + '%'});
	$('#' + element).attr('aria-valuenow', percent);
	$('#' + element + ' span').html(percent >= 10 ? percent + '%' : '&nbsp;');
}

global.adultCheckSave = adultCheckSave;
function adultCheckSave() {
	setCookie('adult', 1, 3650);
	$('#adultCheckModalWindow').modal('hide');
}

global.auctionOffers = auctionOffers;
function auctionOffers(lang, mode, me, auction, nrs) {
	$.ajax({
		url: '/' + lang + '/ajax/auctionoffers/' + auction + '/' + nrs,
		type: 'get',
		dataType: 'json',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status != 200) {
			toaster('danger', '', json.txt);
			return;
		}
		
		var i = 0;
		var chg = 0;
		var timeout = 0;
		var obj, ex;
		var curr = getCookie('currency');
		for (i in json.lots) {
			timeout = $('#lot' + json.lots[i].nr + 'info').data('timeout');
			chg = 0;
			obj = $('#lot' + json.lots[i].nr + 'info');
			if (obj.data('stop') != json.lots[i].stoptime) {
				chg++;
			}
			if (obj.data('bids') != json.lots[i].bids) {
				chg++;
				if (mode == 1) {
					$('#lot' + json.lots[i].nr + 'price .prebids .bids').text(json.lots[i].bids == 0 ? '-' : json.lots[i].bids);
					$('#lot' + json.lots[i].nr + 'price .prebids').show();
				} else {
					$('#lot' + json.lots[i].nr + 'bids strong').text(json.lots[i].bids == 0 ? '-' : json.lots[i].bids);
				}
			}
			if (obj.data('price') != json.lots[i].price.current && json.lots[i].price.current > 0) {
				ex = exchangeCurrency(obj.data('exchange'), curr, json.lots[i].price.current);
				if (mode == 1) {
					$('#lot' + json.lots[i].nr + 'price .price').html(ex.sign + '<strong>' + ex.value + '</strong> ' + ex.acr).addClass('text-success');
				} else {
					$('#currentprice div.value').html(ex.sign + '<strong>' + ex.value + '</strong> ' + '<small>' + ex.acr + '</small>');
					var inp = parseInt(parseInt($('#offervalue').val().replace(/[^0-9]/, '')) * 100);
					if (inp < parseInt(json.lots[i].price.next)) {
						$('#offervalue').val(Math.round(json.lots[i].price.next / 100).formatMoney(0, '.', ' '));
					}
				}
				chg++;
			}
			if (chg > 0) {
				if (mode == 1) {
					$('#lot' + json.lots[i].nr + 'info').css({backgroundColor: "#f3033c"}).animate({backgroundColor: "#fff"}, 600);
				} else {
					$('#lot' + json.lots[i].nr + 'box').css({backgroundColor: "#f3033c"}).animate({backgroundColor: "#f5f5f5"}, 600);
					$('#currentprice').css({backgroundColor: "#f3033c"}).animate({backgroundColor: "#fff"}, 600);
				}
			}
			if (mode == 2) {
				if (json.lots[i].winner > 0 && json.lots[i].winner == me) {
					$('#lot' + json.lots[i].nr + 'winner').show();
				} else {
					$('#lot' + json.lots[i].nr + 'winner').hide();
				}
			}
			if (mode == 1) {
				if (me > 0 && json.lots[i].winner == me) {
					$('#lot' + json.lots[i].nr + 'info .winner').show();
					$('#lot' + json.lots[i].nr + 'info .looser').hide();
				} else {
					$('#lot' + json.lots[i].nr + 'info .winner').hide();
					if (obj.data('youbid') > 0) {
						$('#lot' + json.lots[i].nr + 'info .looser').show();
					}
				}
			}
			obj.data('stop', json.lots[i].stoptime);
			obj.data('price', json.lots[i].price.current);
			obj.data('bids', json.lots[i].bids);
			
			var split = json.lots[i].stoptime.split(' ');
			auctionCountdownShow(obj, split, 1, (mode == 2 ? 1 : 0));
		}
		
		if (mode == 2 && json.lots.length == 1 && timeout == 0 && json.next.id > 0 && json.next.nr != json.lots[i].nr && parseInt($('#lot' + json.lots[i].nr + 'info').data('timeout')) > 0) {
			var auto = getCookie('internetauto');
			if (auto > 0) {
				window.location = json.next.url;
			}
		}
		
	});
}

global.auctionCountdown = auctionCountdown;
function auctionCountdown(extend) {
	$('.lotinfo').each(function(i, e) {
		var el = $(e);
		var split = el.data('stop').split(' ');
		auctionCountdownShow(el, split, 0, extend);
	});
}

global.auctionCountdownShow = auctionCountdownShow;
function auctionCountdownShow(el, split, allowend, extend) {
	var day, hrs, min, sec;

	/*
	var end = moment.tz({year: split[0], month: (split[1] - 1), day: split[2], hour: split[3], minute: split[4], second: split[5]}, split[6]);
	var now = moment();
	var left = (end.diff(now)) / 1000
	*/
	
	let dt = new Date(split[0] + '-' + split[1] + '-' + split[2] + 'T' + split[3] + ':' + split[4] + ':' + split[5] + split[7]);
	let now = new Date().getTime();
	let end = new Date(dt).getTime();
	let left = (end - now) / 1000;

	day = parseInt(left / 86400);
	left = left % 86400;
	hrs = parseInt(left / 3600);
	left = left % 3600;
	min = parseInt(left / 60);
	sec = parseInt(left % 60);

	var html = '';
	if (day > 0) {
		html += '<strong>' + day + '</strong> ' + (day == 1 ? translate('dzien') : translate('dni')) + ' ';
	}
	if (hrs <= 0 && min <= 0 && sec <= 0)  {
		if (allowend > 0 || sec < -30 || min <= -1 || parseInt(el.data('timeout')) > 0) {
			html = '<span style="text-transform:uppercase;">' + translate('koniec czasu') + '</span>';
			el.data('timeout', 1);
		} else {
			html = '<div class="spinner spinner-grow spinner-grow-sm text-muted" role="status"><span class="sr-only">...</span></div>';
		}
		$('#lot' + el.data('nr') + 'box').hide();
	} else {
		html += '' + pad(hrs, 2) + ':' + pad(min, 2) + ':' + pad(sec, 2) + '';
		if (extend > 0) {
			html += '<br><small>' + pad(dt.getMonth() + 1, 2) + '/' + pad(dt.getDate(), 2) + ' ' + pad(dt.getHours(), 2) + ':' + pad(dt.getMinutes(), 2) + ':' + pad(dt.getSeconds(), 2) + '</small>';
		}
	}
	$('#lot' + el.data('nr') + 'countdown').html(html);
}

global.liveCountdown = liveCountdown;
function liveCountdown() {
	let el = $('#auctiontimer');
	let day, hrs, min, sec;
	let split = el.data('live').split(' ');
	
	/*
	let optorg = {
		year: 'numeric', month: 'numeric', day: 'numeric',
		hour: 'numeric', minute: 'numeric', second: 'numeric',
		hour12: false, 
		timeZone: split[6]
	};
	let org = new Intl.DateTimeFormat('en-GB', optorg).format(dt);
	match = org.match(/^([0-9]{2})\/([0-9]{2})\/([0-9]{4}), ([0-9]{2}):([0-9]{2}):([0-9]{2})$/);
	let tmp = new Date(match[3], (match[2] - 1), match[1], match[4], match[5], match[6]);
	*/
	
	let html = '';
	let dt = '';
	dt = new Date(split[0] + '-' + split[1] + '-' + split[2] + 'T' + split[3] + ':' + split[4] + ':' + split[5] + split[7]);
	if (isNaN(dt)) {
		html = split[1] + '/' + split[2] + ' ' + split[3] + ':' + split[4] + ':' + split[5];
		el.html(html);
		return;
	}
	let now = new Date().getTime();
	let end = new Date(dt).getTime();
	let left = (end - now) / 1000;
	
	day = parseInt(left / 86400);
	left = left % 86400;
	hrs = parseInt(left / 3600);
	left = left % 3600;
	min = parseInt(left / 60);
	sec = parseInt(left % 60);
	
	if (day > 0) {
		html += '<strong>' + day + '</strong> ' + (day == 1 ? translate('dzien') : translate('dni')) + ' ';
	}
	if (hrs <= 0 && min <= 0 && sec <= 0) {
		el.hide();
	} else {
		html += '' + pad(hrs, 2) + ':' + pad(min, 2) + ':' + pad(sec, 2) + '';
	}
	el.html(html);
}

/*
function liveCountdownOLD() {
	var el = $('#auctiontimer');
	var day, hrs, min, sec;
	var split = el.data('live').split(' ');

//	var curr = new Date().getTime();
//	var stop = new Date(split[0], (split[1] - 1), split[2], split[3], split[4], split[5]);
//	var left = (stop - curr) / 1000;

	var end = moment.tz({year: split[0], month: (split[1] - 1), day: split[2], hour: split[3], minute: split[4], second: split[5]}, split[6]);
	var now = moment();
	var left = (end.diff(now)) / 1000

	day = parseInt(left / 86400);
	left = left % 86400;
	hrs = parseInt(left / 3600);
	left = left % 3600;
	min = parseInt(left / 60);
	sec = parseInt(left % 60);

	var html = '';
	if (day > 0) {
		html += '<strong>' + day + '</strong> ' + (day == 1 ? translate('dzien') : translate('dni')) + ' ';
	}
	if (hrs <= 0 && min <= 0 && sec <= 0) {
		el.hide();
	} else {
		html += '' + pad(hrs, 2) + ':' + pad(min, 2) + ':' + pad(sec, 2) + '';
	}
	el.html(html);
}
*/

global.auctionBoxCountdown = auctionBoxCountdown;
function auctionBoxCountdown(selector) {
	$(selector).each(function(i, el) {
		var day, hrs, min, sec;
		var split = $(el).data('countdown').split(' ');
		
		/*
		var end = moment.tz({year: split[0], month: (split[1] - 1), day: split[2], hour: split[3], minute: split[4], second: split[5]}, split[6]);
		var now = moment();
		var left = (end.diff(now)) / 1000
		*/
		
		let dt = new Date(split[0] + '-' + split[1] + '-' + split[2] + 'T' + split[3] + ':' + split[4] + ':' + split[5] + split[7]);
		let now = new Date().getTime();
		let end = new Date(dt).getTime();
		let left = (end - now) / 1000;
		
		day = parseInt(left / 86400);
		left = left % 86400;
		hrs = parseInt(left / 3600);
		left = left % 3600;
		min = parseInt(left / 60);
		sec = parseInt(left % 60);
		
		var html = '';
		if (day > 0) {
			html += '<strong>' + day + '</strong>d ';
		}
		if (hrs <= 0 && min <= 0 && sec <= 0) {
			$(el).hide();
		} else {
			html += '' + pad(hrs, 2) + ':' + pad(min, 2) + ':' + pad(sec, 2) + '';
		}
		$(el).html(html);
	});
}

global.searchFilterAdd = searchFilterAdd;
function searchFilterAdd(lang, menu, type, mode, redirect) {
	var filter = '';
	$('#' + menu + ' input.' + type + 'checkbox').each(function(i, e) {
		if ($(e).prop('checked')) {
			filter += (filter.length > 0 ? ',' : '') + $(e).val();
		}
	});
	$.ajax({
		url: '/' + lang + '/ajax/searchfilter/add/' + type + '/' + mode,
		data: 'filter=' + filter,
		dataType: 'json',
		method: 'POST',
		cache: false,
	})
	.always(function() {
		if (redirect.length == 0) {
			location.reload();
		} else {
			window.location = redirect;
		}
	});
}

global.searchFilterPick = searchFilterPick;
function searchFilterPick(lang, type, mode, filter, redirect) {
	$.ajax({
		url: '/' + lang + '/ajax/searchfilter/add/' + type + '/' + mode,
		data: 'filter=' + filter,
		dataType: 'json',
		method: 'POST',
		cache: false,
	})
	.always(function() {
		if (redirect.length == 0) {
			location.reload();
		} else {
			window.location = redirect;
		}
	});
}

global.searchFilterDelete = searchFilterDelete;
function searchFilterDelete(lang, type, mode, filter, redirect) {
	if (type == 'name' || type == 'type') {
		if (redirect.length == 0) {
			location.reload();
		} else {
			window.location = redirect;
		}
		return;
	}
	$.ajax({
		url: '/' + lang + '/ajax/searchfilter/delete/' + type + '/' + mode,
		data: 'filter=' + filter,
		dataType: 'json',
		method: 'POST',
		cache: false,
	})
	.always(function() {
		if (redirect.length == 0) {
			location.reload();
		} else {
			window.location = redirect;
		}
	});
}

/*
global.searchClear = searchClear;
function searchClear(lang, type, param, redirect) {
	var newgrades = [];
	var newlabels = [];
	var i = 0;
	if (type == 'grade') {
		var grades = getCookie('grades').split(",");
		for (i in grades) {
			if (grades[i] == param) continue;
			newgrades.push(grades[i])
		}
	}
	if (type == 'label') {
		var labels = getCookie('labels').split(",");
		for (i in labels) {
			if (labels[i] == param) continue;
			newlabels.push(labels[i])
		}
	}
	if (type == 'all' || (type == 'grade' && newgrades.length == 0)) {
		document.cookie = 'grades=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.onebid.pl; path=/; secure';
	}
	if (type == 'all' || (type == 'label' && newlabels.length == 0)) {
		document.cookie = 'labels=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.onebid.pl; path=/; secure';
	}
	//mainSearchSwitch(lang, 'onlyme', 0, 0);	// Useless?
	if (newgrades.length > 0) {
		setCookie('grades', newgrades, 500);
	}
	if (newlabels.length > 0) {
		setCookie('labels', newlabels, 500);
	}
	if (redirect.length > 0) {
		window.location = redirect;
	} else {
		location.reload();
	}
}
*/
/*
global.searchSetPainting = searchSetPainting;
function searchSetPainting(lang, type, value) {
	var cookie = getCookie('painting');
	if (cookie.length == 0) {
		location.reload();
		return;
	}
	painting = JSON.parse(decodeURIComponent(cookie));
	if (type == 'colors') {
		painting.colors = value;
	}
	if (type == 'size') {
		painting.size = value;
	}
	if (type == 'width') {
		var split = value.split('-');
		painting.width = [split[0], split[1]];
	}
	if (type == 'height') {
		var split = value.split('-');
		painting.height = [split[0], split[1]];
	}
	setCookie('painting', JSON.stringify(painting), 500);
	location.reload();
}
*/

global.archiveSearch=archiveSearch;
function archiveSearch(url) {
	var name = $('#archivesearch').val().trim();
	window.location = url.replace(/@@@/, name);
}

global.artistFavourite = artistFavourite;
function artistFavourite(lang, id) {
	$('#artistfav' + id + ' .favouritestar').addClass('spin');
	$.ajax({
		url: '/' + lang + '/ajax/artistfavourite/' + id,
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		var i = 0;
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		if (json.status == 1 || json.status == 2) {
			toaster('success', '', translate('zapisano'));
			if (json.status == 1) {
				$('#artistfav' + id + ' .favouritestar').removeClass('text-muted').addClass('text-primary');
			} else {
				$('#artistfav' + id + ' .favouritestar').removeClass('text-primary').addClass('text-muted');
			}
		}
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	})
	.always(function() {
		$('#artistfav' + id + ' .favouritestar').removeClass('spin');
	});
}

global.indexAuctionboxCount = indexAuctionboxCount;
function indexAuctionboxCount(type, sort, value) {
	if (!/^(4|8|12|16)$/.test(value)) {
		value = 4;
	}
	var cookie = getCookie('auctionsinfo');
	if (cookie.length > 0) {
		var json = JSON.parse(decodeURIComponent(cookie));
		if (json[type]['i']['c'] !== undefined) {
			json[type]['i']['c'] = value;
			json[type]['i']['s'] = sort;
			json[type]['i']['m'] = 1;
			setCookie('auctionsinfo', encodeURIComponent(JSON.stringify(json)), 10000);
		}
	}
	location.reload();
}

global.indexAuctionboxMove = indexAuctionboxMove;
function indexAuctionboxMove(type, sort, value) {
	if (!/^(-1|1)$/.test(value)) {
		location.reload();
		return;
	}
	var cookie = getCookie('auctionsinfo');
	if (cookie.length > 0) {
		var json = JSON.parse(decodeURIComponent(cookie));
		if (json[type]['i']['s'] !== undefined) {
		//	var sort = 1;
			var i = 0;
			var base = json[type]['i']['s'];
			var tmp = json;
			var len = tmp.length;
			if (base + value <= 0 || base + value > len) {
				return;
			}
			console.log('DEST ' + (sort + value));
			for (i in json) {
				console.log('NOW ' + i + ' org: ' + json[i]['i']['s']);
				if (i == type) {
					tmp[i]['i']['s'] = sort + value;
				} else if (json[i]['i']['s'] == sort + value) {
					console.log('MOVE ' + i + ' => ' + sort + ' + ' + value);
					console.log('OMG ' + i);
					if (value > 0) {
						tmp[i]['i']['s'] = tmp[i]['i']['s'] - 1;
					} else {
						tmp[i]['i']['s'] = tmp[i]['i']['s'] + 1;
					}
				}
				console.log(i + ' :');
				console.log(tmp[i]['i']);
			}
			setCookie('auctionsinfo', encodeURIComponent(JSON.stringify(tmp)), 10000);
		}
	}
	location.reload();
}

global.getLotsForYou = getLotsForYou;
function getLotsForYou(lang) {
	$.ajax({
		url: '/' + lang + '/ajax/getlotsforyou',
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		var i = 0;
		if (jqXHR.status == 200 && json.id > 0) {
			var html = '<div class="img"><a href="' + json.url + '"><img src="' + json.img + '" class="img-fluid"><div class="dot">' + $('#lotsforyou').data('text') + '</div></a></div>';
			html += '<div class="info"><div class="price"><strong>' + Math.round(json.price / 100).formatMoney(0, '.', ' ') + '</strong> <small class="text-muted">' + json.currency + '</small></div>';
			html += '<div class="name">' + json.name + '</div>';
			html += '<a href="' + json.url + '" class="btn btn-link mt-2">' + $('#lotsforyou').data('order') + '</a>';
			html += '</div>';
			$('#lotsforyou .currentoffer').append(html);
			$('#lotsforyou').show();
		}
	});
}

global.lastVisitedReset = lastVisitedReset;
function lastVisitedReset() {
	var str = '';
	var date = new Date();
	str = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0') + '.';
	date.setDate(date.getDate() + 1);
	str += date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
	setCookie('lastvisited', str, 2000);
}

global.offerOrderConfirm = offerOrderConfirm;
function offerOrderConfirm(lang) {
	$.ajax({
		url: '/' + lang + '/ajax/checkloggeduser',
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		var i = 0;
		if (jqXHR.status != 200) {
			toaster('danger', '', translate('blad'));
			return;
		}
		if (json.logged == 0) {
			$('#offerOrderLogin').show();
			$('#offerOrderConfirm').hide();
			$('#offerOrderButton').hide();
		} else {
			$('#offerOrderLogin').hide();
			$('#offerOrderConfirm').show();
			$('#offerOrderButton').show();
		}
		$('#offerOrderModal').modal('show');

	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	});
}

global.offerOrderMake = offerOrderMake;
function offerOrderMake(lang, id, price) {
	$('#mainpreloader').show();
	$.ajax({
		url: '/' + lang + '/ajax/offer/order/' + id,
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		var i = 0;
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		$('#offerOrderModal').modal('hide');
		$('#offerOrderText').show();
		$('#offerOrderPrice').hide();
	//	ga('send', 'event', 'Lot', 'Ask', 'Offer', Math.round(price / 100));
		gtag('event', 'Lot', {
			"EventAction": "Ask",
			"EventLabel": "Offer",
			"EventValue": Math.round(price / 100)
		});
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	})
	.always(function() {
		$('#mainpreloader').hide();
	});
}

global.offerIOD = offerIOD;
function offerIOD(lang, type) {
	var cookie = getCookie('offeriod');
	if (cookie != '') return;
	if (/bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent)) {
		return;
	}
	$.ajax({
		url: '/' + lang + '/ajax/offer/iod/' + type,
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		var i = 0;
		if (jqXHR.status != 200) {
			return;
		}
		if (json.status > 0) {
			var attrs = {};
			var imgheight = (window.innerWidth < 1000 ? 250 : 300);
			$('#offerIODModal').modal('show');
			$('#offerIODName').text(json.name);
			$('#offerIODPrice').text(Math.round(json.price / 100).formatMoney(0, '.', ' '));
			$('#offerIODCurrency').text(json.currency);
			$('#offerIODImage').prop('src', json.img).css({height: imgheight + 'px'});
			
			$.each($('#offerIODURL')[0].attributes, function(idx, attr) {
				attrs[attr.nodeName] = attr.nodeValue;
			});
			attrs['href'] = json.url;
			$('#offerIODURL').replaceWith(function () {
				return $("<a />", attrs).append($(this).contents());
			});
			
			$.each($('#offerIODButton')[0].attributes, function(idx, attr) {
				attrs[attr.nodeName] = attr.nodeValue;
			});
			attrs['href'] = json.url;
			$('#offerIODButton').replaceWith(function () {
				return $("<a />", attrs).append($(this).contents());
			});
			
			setCookie('offeriod', 1, 3);
		}
	});
}

global.paymentInit=paymentInit;
function paymentInit(lang, type, item, method, arg) {
	$('#mainpreloader').show();
	$.ajax({
		url: '/' + lang + '/ajax/payment/init/'+ type + '/' + item + '/' + method + '/' + arg,
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			$('#mainpreloader').hide();
			return;
		}
		if (method == 'online' || method == 'blik' || method == 'paypal') {
			window.location = json.url;
		} else if (method == 'card') {
			window.location = json.url;
		}
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
		$('#mainpreloader').hide();
	});
}

global.paymentAuctionList=paymentAuctionList;
function paymentAuctionList(lang, auction) {
	$.ajax({
		url: '/' + lang + '/ajax/payment/auction/list/' + auction,
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		var i = 0;
		var html = '';
		var icon = '';
		var method = '';
		for (i in json.list) {
			icon = 'help_outline';
			method = '<span class="material-icons">help_outline</spani>';
			if (json.list[i].method == 'online') {
				method = '<span class="material-icons text-muted">account_balance</span>';
			} else if (json.list[i].method == 'card') {
				method = '<span class="material-icons text-muted">credit_card</span>';
			} else if (json.list[i].method == 'paypal') {
				method = '<span class="material-icons text-muted">currency_exchange</span>';
			} else if (json.list[i].method == 'blik') {
				method = '<img src="/images/ico/payment-blik.png" class="img-fluid ico" />';
			} else if (json.list[i].method == 'applepay') {
				method = '<img src="/images/ico/payment-applepay.png" class="img-fluid ico" />';
			}
			if (json.list[i].status == 1) {
				icon = 'schedule';
			} else if (json.list[i].status == 2) {
				icon = 'schedule';
			} else if (json.list[i].status == 3) {
				icon = 'done';
			} else if (json.list[i].status == 4) {
				icon = 'close';
			} else if (json.list[i].status == 5) {
				icon = 'block';
			} else if (json.list[i].status == 9) {
				icon = 'schedule';
			}
			html += '<li class="list-group-item"><div class="row">';
			html += '<div class="col-sm-2 col-xs-3 text-center">' + method + '</div>';
			html += '<div class="col-sm-4 col-xs-9">' + json.list[i].date + '</div>';
			html += '<div class="col-sm-4 col-xs-10 text-right">' + (json.list[i].amount / 100).formatMoney(2, '.', ' ') + '</div>';
			html += '<div class="col-sm-2 col-xs-2 text-center"><span class="material-icons">' + icon + '</span></div>';
			if (json.list[i].descr.length > 0) {
				html += '<div class="offset-sm-2 col-sm-10 offset-xs-3 col-xs-9 text-muted">' + json.list[i].descr + '</div>';
			}
			html += '</div></li>';
		}
		if (html.length > 0) {
			$('#paymenthistorylist').html(html);
		} else {
			$('#paymenthistory .empty').show();
		}
		$('#paymenthistory .loader').hide();
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
	//	toaster('danger', '', translate('blad'));
	});
}

/*
// Niepotrzebne?
global.sidebarLotsLoad = sidebarLotsLoad;
function sidebarLotsLoad(type, show) {
	var locale = $('#lotssidebar').data('locale');
	$('#' + show + ' .lots').html('<div class="p-3 text-center text-muted w-100"><i class="material-icons spin">settings</i></div>');
	$.ajax({
		url: '/' + locale + '/ajax/get_' + type,
		dataType: 'json',
		method: 'GET',
		cache: false
	})
	.done(function(json) {
		sidebarLotsRender(type, json.lots, show);
	});
}

global.sidebarLotsRender = sidebarLotsRender;
function sidebarLotsRender(type, data, show) {
	var html = '';
	data = Object.entries(data);
	var locale = $('#lotssidebar').data('locale');
	if (data.length > 0) {
		data.forEach(function(row){
			html += '<div class="row">';
			if (type == "favourites") {
				var auction=row[1]['auction'];
				html += '<div class="col-12 logo"><div class="box pb-2"><img src="/images/logos/'+auction['company']+'.png" alt="" class="img-fluid p-2" loading="lazy"><p>' + auction['name'] + '</p></div></div>';
				var arr = row[1]['lots'];
			} else {
				html += '<div class="col-12 logo"><div class="box p-2"><p><i class="fa fa-table pr-2"></i>' + row[0] + '</p></div></div>';
				var arr=row[1];
			}
			for (i = 0; i < arr.length; i++) {
				html += '<div class="col-6 lot"><a href="/' + locale + '/auction/' + arr[i]['auction'] + '/lot/' + arr[i]['nr'] + '"><img src="https://img1.onebid.pl/img/' + arr[i]['auction'] + '/' + arr[i]['extid'] + '_1q.jpg" alt="" class="img-fluid" loading="lazy"></a></div>';
			}
			html += '</div>';
		});
	} else {
		html += '<p class="p-2">' + $('#lotssidebar').data('empty') + '</p>';
	}
	$('#' +show + ' .lots').html(html);
}
*/

global.lotObserve = lotObserve;
function lotObserve(lang, auction, lot) {
	$('#observe' + auction + '-' + lot + ' .star').addClass('spin');
	$('#lotActionSuccessMore').hide();
	$.ajax({
		url: '/' + lang + '/ajax/lotobserve/' + auction + '/' + lot,
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		var i = 0;
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		if (json.data.status == 1 || json.data.status == 2) {
			//	toaster('success', '', translate('zapisano'));
			if (json.data.status == 1) {
				$('#observe' + auction + '-' + lot).addClass('active');
				$('#observe' + auction + '-' + lot + ' .star').text('star');
			} else {
				$('#observe' + auction + '-' + lot).removeClass('active');
				$('#observe' + auction + '-' + lot + ' .star').text('star_border');
			}
		}
		lotActionSuccess(json.lots);
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	})
	.always(function() {
		$('#observe' + auction + '-' + lot + ' .star').removeClass('spin');
	});
}

global.lotActionSuccess = lotActionSuccess;
function lotActionSuccess(json) {
	var i = 0;
	var html = '';
	var button = $('#lotActionSuccessWindow').data('button');
	for (i in json) {
		html += '<div class="col-6 col-sm-4 col-md-4 col-lg-3 ' + (i <= 1 ? '' : 'd-none' + (i <= 2 ? ' d-sm-block' : ' d-sm-none d-lg-block')) + ' lot">';
		html += '<a href="' + json[i].url + '" onclick="gtag(\'event\', \'Lot\', {\'EventAction\': \'Personalized\', \'EventLabel\': \'Auto\'});"><img src="https://img1.one.bid/img/' + json[i].auction + '/' + json[i].extid + '_1q.jpg" class="img-fluid" alt="" /></a>';
		html += '<div class="name">' + json[i].name + '</div>';
		html += '<div class="text-right mb-2"><strong>' + Math.round(json[i].price / 100).formatMoney(0, '.', ' ') + '</strong> <small class="text-muted">' + json[i].currency + '</small></div>';
		html += '<div class="text-center"><a href="' + json[i].url + '" class="btn btn-link" onclick="ggtag(\'event\', \'Lot\', {\'EventAction\': \'Personalized\', \'EventLabel\': \'Auto\'});">' + button + '</a></div>'
		html += '</div>';
	}
	$('#lotActionSuccessList').html(html);
	$('#lotActionSuccessMore').show();
	$('#lotActionSuccessWindow').modal('show');
	$('#lotActionSuccessProgress').data('value', 0).css({width: '0%'});
	const lotActionSuccessInterval = setInterval(function() {
		var val = $('#lotActionSuccessProgress').data('value');
		if (val >= 50) {
			$('#lotActionSuccessWindow').modal('hide');
			clearInterval(lotActionSuccessInterval);
			return;
		}
		val = val + 1;
		$('#lotActionSuccessProgress').data('value', val).css({width: (val * 2) + '%'});
	}, 100);
}

global.limitsFrameGet = limitsFrameGet;
function limitsFrameGet(lang, auction) {
	$.ajax({
		url: '/' + lang + '/ajax/lotlimitget/' + auction,
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status == 200) {
			limitsFrameShowList(lang, auction, json.limits, 0);
		}
	});
}

global.limitsFrameShowList = limitsFrameShowList;
function limitsFrameShowList(lang, auction, json, add) {
	if (json.count == 0) {
		$('#limitsframe').hide();
		return;
	}
	$('#limitstotalcount').text(json.count);
	$('#limitstotalsum').text(Math.round(json.sum / 100).formatMoney(0, '.', ' '));
	if (json.sum > json.credit) {
		$('#limitstotaloverbid').show();
	}
	var html = '';
	var i = 0;
	if (add > 0) {
		if ($('#lot' + json.list[i].lot + 'limit').length) {
			$('#lot' + json.list[i].lot + 'limit').slideUp("fast", function() {
				$('#lot' + json.list[i].lot + 'limit').remove();
				if (json.list[i].limitvalue > 0) {
					html += '<a href="/' + lang + '/auction/' + auction + '/lot/' + json.list[i].nr + (/^[0-9]+$/.test(json.list[i].letter) ? '.' : '') + json.list[i].letter + '" class="list-group-item" id="lot' + json.list[i].lot + 'limit" style="display:none;">' + limitsFrameShowRow(auction, json.live, json.list[i]) + '</a>';
					$('#limitsframe .list-group').show().prepend(html);
					$('#lot' + json.list[i].lot + 'limit').show();
				}
			});
		} else {
			html += '<a href="/' + lang + '/auction/' + auction + '/lot/' + json.list[i].nr + (/^[0-9]+$/.test(json.list[i].letter) ? '.' : '') + json.list[i].letter + '" class="list-group-item" id="lot' + json.list[i].lot + 'limit" ">' + limitsFrameShowRow(auction, json.live, json.list[i]) + '</a>';
			$('#limitsframe .list-group').prepend(html);
		}
	} else {
		for (i in json.list) {
			html += '<a href="/' + lang + '/auction/' + auction + '/lot/' + json.list[i].nr + (/^[0-9]+$/.test(json.list[i].letter) ? '.' : '') + json.list[i].letter + '" class="list-group-item" id="lot' + json.list[i].lot + 'limit">' + limitsFrameShowRow(auction, json.live, json.list[i]) + '</a>';
		}
		$('#limitsframe .list-group').html(html);
	}
	if (json.tosend > 0) {
		$('#limitstosend').show();
	}
	$('#limitsframe').show();
}

global.limitsFrameShowRow = limitsFrameShowRow;
function limitsFrameShowRow(auction, live, json) {
	var html = '<div class="row">';
	html += '<div class="col-xs-3 col-lg-2 nr"><strong>' + json.nr + (/^[0-9]+$/.test(json.letter) ? '.' : '') + json.letter + '</strong> ';
	if (live > 0) {
		html += (json.status == 2 ? '' : '<i class="material-icons text-muted">check_circle</i>');
	} else {
		if (json.nolivelimit == 0 && json.noliveprepare > 0) {
			html += '<i class="material-icons text-muted">check_circle</i>';
		} else if (json.nolivelimit > 0 && json.noliveprepare == 0) {
			html += '<i class="material-icons text-danger">check_circle</i>';
		} else if (json.nolivelimit > 0 && json.noliveprepare > 0 && json.nolivelimit == json.noliveprepare) {
			html += '<i class="material-icons text-success">check_circle</i>';
		} else if (json.nolivelimit > 0 && json.noliveprepare > 0 && json.nolivelimit != json.noliveprepare) {
			html += '<i class="material-icons text-info">sync</i>';
		}
	}
	html += '</div>';
	html += '<div class="col-xs-3 col-lg-2 img">' + (json.img == 0 ? '&nbsp;' : '<img src="//img1.one.bid/img/' + auction + '/' + json.extid + '_1s.jpg" class="img-fluid"/>') + '</div>';
	html += '<div class="col-xs-6 col-lg-8"><div class="text-muted name">' + json.name + '</div><div class="text-right value">' + Math.round(json.limitvalue / 100).formatMoney(0, '.', ' ') + '</div></div>';
	html += '</div>';
	return html
}

global.limitsFrameToggle = limitsFrameToggle;
function limitsFrameToggle() {
	var frame = getCookie('limitsframe');
	var value = (frame == '' || frame == 1 ? 0 : 1);
	setCookie('limitsframe', value, 500);
	if (value == 0) {
		$('#limitsframe .limitsframecontent').hide();
		$('#limitsframe .toggler').text('expand_less');
	} else {
		$('#limitsframe .limitsframecontent').show();
		$('#limitsframe .toggler').text('expand_more');
	}
}

global.timezoneCalculate = timezoneCalculate;
function timezoneCalculate(date, time, offset, timezone, dictionary) {
	let json = {
		'active': 0,
		'offset': 0,
		'yyyy': '0000',
		'mm': '00',
		'dd': '00',
		'hhmm': '00:00',
		'dow': '',
		'dom': '',
		'city': '',
		'abbr': '',
		'obj': new Date()
	};
	let optloc = {
		year: 'numeric', month: 'numeric', day: 'numeric',
		hour: 'numeric', minute: 'numeric', second: 'numeric',
		hour12: false
	};
	let optorg = {
		year: 'numeric', month: 'numeric', day: 'numeric',
		hour: 'numeric', minute: 'numeric', second: 'numeric',
		hour12: false, 
		timeZone: timezone
	};
	let dt = new Date(date + 'T' + time + offset);
	let loc = new Intl.DateTimeFormat('en-GB', optloc).format(dt);
	let org = new Intl.DateTimeFormat('en-GB', optorg).format(dt);
	let tz = new Intl.DateTimeFormat('en-GB', {timeZoneName: 'short'}).format(dt);
	
	match = loc.match(/^([0-9]{2})\/([0-9]{2})\/([0-9]{4}), ([0-9]{2}):([0-9]{2}):([0-9]{2})$/);
	if (!match) {
		return json;
	}
	json.obj = new Date(parseInt(match[3]), parseInt(match[2]) - 1, parseInt(match[1]), parseInt(match[4]), parseInt(match[5]), parseInt(match[6]));
	
	if (loc != org) {
		json.active = 1;
		json.yyyy = match[3];
		json.mm = match[2];
		json.dd = match[1];
		json.hhmm = match[4] + ':' + match[5];
		json.abbr = tz.split(' ').pop();
		json.city = Intl.DateTimeFormat().resolvedOptions().timeZone.split('/').pop();
		if (dictionary !== null) {
			json.dow = dictionary['dayofweek' + dt.getDay()];
			json.dom = dictionary['dayofmonth' + json.mm];
		}
	}
	return json;
}
/*
function timezoneCalculate(date, time, offset, dictionary) {
	var json = {
		'active': 0,
		'offset': 0,
		'yyyy': '0000',
		'mm': '00',
		'dd': '00',
		'hhmm': '00:00',
		'dow': '',
		'dom': '',
		'city': '',
		'abbr': '',
	};
	var mytime = new Date();
	var myoffset = parseInt(mytime.getTimezoneOffset() * 60 * -1);
	var dt = moment(date + ' ' + time).utcOffset(offset);
//	var dt = moment(date + ' ' + time).utcOffset(myoffset / 10);
	if (myoffset != offset) {
		json.active = 1;
		json.offset = myoffset;
		json.yyyy = dt.format('YYYY');
		json.mm = dt.format('MM');
		json.dd = dt.format('DD');
		json.hhmm = dt.format('HH:mm');
		json.abbr = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');
		json.city = Intl.DateTimeFormat().resolvedOptions().timeZone.split('/').pop();
		if (dictionary !== null) {
			json.dow = dictionary['dayofweek' + dt.isoWeekday()];
			json.dom = dictionary['dayofmonth' + dt.format('MM')];
		}
	}
	return json;
}
*/

global.remoteLoginOpen = remoteLoginOpen;
function remoteLoginOpen(redirect = '') {
	$('#remoteLoginModal').data('redirect', redirect);
	$('#remoteLoginModal').modal('show');
}

global.remoteLoginMake = remoteLoginMake;
function remoteLoginMake(lang) {
	var $btn = $('#remoteLoginButton');
	$btn.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ' + $btn.data('loading-text')).prop('disabled', true);
	var data = 'mail=' + $('#remote_login_mail').val().trim() + '&password=' + $('#remote_login_password').val().trim();
	$.ajax({
		url: '/' + lang + '/ajax/remote/login',
		dataType: 'json',
		data: data,
		method: 'POST',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
		$('#mainpreloader').show();
		if ($('#remoteLoginModal').data('redirect').length == 0) {
			location.reload();
		} else {
			window.location = $('#remoteLoginModal').data('redirect');
		}
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	})
	.always(function() {
		$btn.html($btn.data('normal-text')).prop('disabled', false);
	});
}

global.remoteRegisterOpen = remoteRegisterOpen;
function remoteRegisterOpen() {
	$('#remoteRegisterModal').modal('show');
}

global.completeUserFormLoad = completeUserFormLoad;
function completeUserFormLoad(locale, full) {
	if (full == 1) {
		$('#SMSCodeModalWindow').modal('show');
		return;
	}
	$.ajax({
		url: '/' + locale + '/ajax/profile/full_form',
		dataType: 'json',
		method: 'GET',
		cache: false
	})
	.done(function (json, textStatus, jqXHR) {
		if (json.code == 300) {
			window.location = json.url;
			return;
		}
		$('#registerFormErrorsList').hide();
		if (!$('#completeUserModal').length) {
			$('#completeUserContainer').html(json.response);
		}
		mdc.autoInit();
		$('#completeUserModal').modal('show');
	});
}

global.completeUserFormSend = completeUserFormSend;
function completeUserFormSend() {
	var data = $('#registerform').serializeArray();
	$('#completeUserFormJSErrors').hide();
	$.ajax({
		url: $('#registerform').attr('action'),
		data: data,
		dataType: 'json',
		method: 'POST',
		cache: false
	})
	.done(function (json, textStatus, jqXHR) {
		if (json.status == 0) {
			var i = 0;
			var error = '';
			var html = '';
			for (i in json.errors) {
				error = i;
				html += '<div class="alert alert-danger">' + json.errors[i] + '</div>';
			}
			var element = error.replace('form.', '');
			if (element.length > 0 && $('#' + element).length) {
				$('#' + element).removeClass('has-success').addClass('has-error incorrect');
				$('#' + element).parent().addClass('mark-error mdc-text-field--invalid');
				$('html, body').animate({
					scrollTop: ($('#' + element).offset().top - 200)
				}, 400);
				$('#' + element).focus();
			}
			toaster('danger', '', translate('formularz zawiera bledy'));
			if (html.length > 0) {
				$('#completeUserFormJSErrors .card-body').html(html);
				$('#completeUserFormJSErrors').show();
			}
			return;
		}
		$('#completeUserModal').modal('hide');
		$('.completeusercheckbutton').off();
		$('.completeusercheckbutton').removeAttr('onclick');
		if(typeof $('.completeusercheckbutton').data('target') !== 'undefined') {
			$('.completeusercheckbutton').attr('data-toggle','modal')
		} else {
			let fun = $('.completeusercheckbutton').data('click');
			$('.completeusercheckbutton').attr('onclick', fun);
			$('.completeusercheckbutton').removeAttr('data-click');
		}
		$('#completeUserSuccess p').html($('#completeUserModal').data('dict-success'));
		$('#completeUserSuccess').removeClass('scale-out-center').addClass('scale-in-center').show();
		setTimeout(function() {$('#completeUserSuccess').removeClass('slide-in-bck-center').addClass('scale-out-center')}, 5000);
		//	toaster('success', '', 'OK');
		//	ga('send', 'event', 'Account', 'Full');
		gtag('event', 'Account', {
			"EventAction": "Full"
		});
		if ($('#completeUserContainer').data('reload') > 0) {
			location.reload();
		}
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	});
}

global.notificationsUpdate = notificationsUpdate;
function notificationsUpdate() {
	$.ajax({
		url: '/api/notifications',
		dataType: 'json',
		method: 'GET',
		cache: false
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status == 200) {
			if (json.info.counters.messages > 0) {
				$('.profile-notification-messages').text(json.info.counters.messages).show();
			} else {
				$('.profile-notification-messages').hide();
			}
			if (json.info.counters.negotiations.seller > 0) {
				$('.profile-notification-negotiations-seller').text(json.info.counters.negotiations.seller).show();
			} else {
				$('.profile-notification-negotiations-seller').hide();
			}
			if (json.info.counters.negotiations.user > 0) {
				$('.profile-notification-negotiations-user').text(json.info.counters.negotiations.user).show();
			} else {
				$('.profile-notification-negotiations-user').hide();
			}
			if (json.info.counters.messages > 0 || json.info.counters.negotiations.seller > 0 || json.info.counters.negotiations.user > 0) {
				$('.profile-notification-negotiations-user').hide();
			}
		}
	});
}

global.firstStepsShow = firstStepsShow;
function firstStepsShow(lang) {
	$.ajax({
		url: '/' + lang + '/ajax/get/page/firststeps',
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status != 200) {
			toaster('danger', '', json.message);
			return;
		}
	//	$('#quickGuideModalBody').html(json.html);
	//	$('#quickGuideModalWindow').modal('show');
		document.body.innerHTML += '<div class="modal fade" tabindex="-1" id="quickGuideModalWindow"><div class="modal-dialog modal-xl"><div class="modal-content"><div class="modal-body">' + json.html + '</div><div class="modal-footer"><button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><span class="material-icons">close</span></button></div></div></div></div>';
		$('#quickGuideModalWindow').modal('show');
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	});
}

global.cookiesContestOpen = cookiesContestOpen;
function cookiesContestOpen(lang) {
	$.ajax({
		url: '/' + lang + '/ajax/get/modal/cookies',
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status != 200) {
			return;
		}
		$('body').append(json.html)
		$('#cookiesContestModal').modal('show')
	});
}

global.questionnaireAutostart = questionnaireAutostart;
function questionnaireAutostart(lang, id, element) {
	if (!getCookie('questionnaire' + id)){
		setCookie('questionnaire' + id, true, 30);
		questionnaireModalOpen(lang, id, element);
	}
}

global.questionnaireModalOpen = questionnaireModalOpen;
function questionnaireModalOpen(lang, id, element) {
	let ua = navigator.userAgent.toLowerCase();
	if (ua == undefined || /(bot|crawl|slurp|spider)/.test(ua)) {
		return;
	}
	$.ajax({
		url: '/' + lang + '/ajax/get/page/questionnaire/' + id,
		dataType: 'json',
		method: 'GET',
		cache: false,
	})
	.done(function (json, textStatus, jqXHR) {
		if (jqXHR.status != 200) {
			toaster('danger', '', translate('blad'));
			return;
		}
		
		if (element == null) {
			let html = '<div class="modal fade" id="questionnaireModalWindow" tabindex="-1" aria-hidden="true"><div class="modal-dialog-centered modal-dialog modal-xl"><div class="modal-content"><div class="modal-body"></div></div></div></div>';
			$('body').append(html);
			$('#questionnaireModalWindow .modal-body').html(json.html);
			$('#questionnaireModalWindow').modal('show');
		} else {
			$('#' + element).html(json.html);
			$('#questionnaireClose').addClass('d-none');
		}
	})
	.fail(function (jqXHR, textStatus, errorThrown) {
		toaster('danger', '', translate('blad'));
	});
}

global.fancyboxImageFilters = fancyboxImageFilters;
function fancyboxImageFilters() {
	var contrast = $('#fancyboxcontrastfilter').val();
	var brightness = $('#fancyboxbrightnessfilter').val();
	$('.fancybox-slide--complete img').css({
		'filter'		: 'contrast('+ contrast + ') brightness('+ brightness + ')',
		'-webkit-filter' 	: 'contrast('+ contrast + ') brightness('+ brightness + ')',
		'-moz-filter'    	: 'contrast('+ contrast + ') brightness('+ brightness + ')',
		'-o-filter'      	: 'contrast('+ contrast + ') brightness('+ brightness + ')',
		'-ms-filter'     	: 'contrast('+ contrast + ') brightness('+ brightness + ')',
	});
}

global.setCookie = setCookie;
function setCookie(cname, cvalue, exdays) {
	var base = document.domain.replace(/^(www\.)/, '');
	var parts = base.split('.');
	/*
	if (parts.length > 2) {
		var subdomain = parts.shift();
	}
	var domain = parts.join('.');
	*/
	var domain = parts.slice(-2).join('.');
	
	// delete old without secure option if exists
	document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=." + domain + "; path=/;";
	
	if (base != domain) {
		// delete cookie set for too long domain
		document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=." + base + "; path=/;";
		document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=." + base + "; path=/;secure";
	}
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	var cookie = cname + "=" + cvalue + "; " + expires + ";domain=." + domain + ";path=/;secure";
	document.cookie = cookie;
}

global.getCookie = getCookie;
function getCookie(cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

Number.prototype.formatMoney = function(c, d, t) {
	var n = this,
	c = isNaN(c = Math.abs(c)) ? 2 : c,
	d = d == undefined ? "." : d,
	t = t == undefined ? "," : t,
	s = n < 0 ? "-" : "",
	i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
	j = (j = i.length) > 3 ? j % 3 : 0;
	return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};
global.pad=pad;
function pad(num, size) {
	var s = "000000000" + num;
	return s.substr(s.length-size);
}
global.thousandMask=thousandMask;
function thousandMask(x) {
	ret = x ? parseFloat(x.replace(/ /g, '')) : '';
	if (/^[0]+$/.test(ret) || !/[0-9]+$/.test(ret)) return x;
	return ret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
global.printFullDate = printFullDate;
function printFullDate(string) {
	let date = new Date(string);
	return ("0" + date.getDate()).slice(-2)+"."+("0"+(date.getMonth()+1)).slice(-2)+"."+date.getFullYear()+" "+("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
}
$.fn.isInViewport = function() {
	var elementTop = $(this).offset().top;
	var elementBottom = elementTop + $(this).outerHeight();
	var viewportTop = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).height();
	return elementBottom > viewportTop && elementTop < viewportBottom;
};
